/**
 * @license
 * MyFonts Webfont Build ID 3729617, 2019-03-06T13:46:00-0500
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed fonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: Gilroy-Thin by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/thin/
 * Copyright: Copyright &#x00A9; 2016 by Radomir Tinkov. All rights reserved.
 *
 * Webfont: Gilroy-UltraLightItalic by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/ultra-light-italic/
 * Copyright: Copyright &#x00A9; 2015 by Radomir Tinkov. All rights reserved.
 *
 * Webfont: Gilroy-ThinItalic by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/thin-italic/
 * Copyright: Copyright &#x00A9; 2015 by Radomir Tinkov. All rights reserved.
 *
 * Webfont: Gilroy-SemiBoldItalic by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/semi-bold-italic/
 * Copyright: Copyright &#x00A9; 2015 by Radomir Tinkov. All rights reserved.
 *
 * Webfont: Gilroy-SemiBold by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/semi-bold/
 * Copyright: Copyright &#x00A9; 2016 by Radomir Tinkov. All rights reserved.
 *
 * Webfont: Gilroy-UltraLight by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/ultra-light/
 * Copyright: Copyright &#x00A9; 2016 by Radomir Tinkov. All rights reserved.
 *
 * Webfont: Gilroy-Regular by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/regular/
 * Copyright: Copyright &#x00A9; 2016 by Radomir Tinkov. All rights reserved.
 *
 * Webfont: Gilroy-RegularItalic by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/regular-italic/
 * Copyright: Copyright &#x00A9; 2015 by Radomir Tinkov. All rights reserved.
 *
 * Webfont: Gilroy-MediumItalic by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/medium-italic/
 * Copyright: Copyright &#x00A9; 2015 by Radomir Tinkov. All rights reserved.
 *
 * Webfont: Gilroy-Medium by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/medium/
 * Copyright: Copyright &#x00A9; 2016 by Radomir Tinkov. All rights reserved.
 *
 * Webfont: Gilroy-LightItalic by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/light-italic/
 * Copyright: Copyright &#x00A9; 2015 by Radomir Tinkov. All rights reserved.
 *
 * Webfont: Gilroy-Light by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/light/
 * Copyright: Copyright &#x00A9; 2016 by Radomir Tinkov. All rights reserved.
 *
 * Webfont: Gilroy-HeavyItalic by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/heavy-italic/
 * Copyright: Copyright &#x00A9; 2015 by Radomir Tinkov. All rights reserved.
 *
 * Webfont: Gilroy-Heavy by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/heavy/
 * Copyright: Copyright &#x00A9; 2016 by Radomir Tinkov. All rights reserved.
 *
 * Webfont: Gilroy-ExtraBoldItalic by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/extra-bold-italic/
 * Copyright: Copyright &#x00A9; 2015 by Radomir Tinkov. All rights reserved.
 *
 * Webfont: Gilroy-ExtraBold by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/extra-bold/
 * Copyright: Copyright &#x00A9; 2016 by Radomir Tinkov. All rights reserved.
 *
 * Webfont: Gilroy-BoldItalic by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/bold-italic/
 * Copyright: Copyright &#x00A9; 2015 by Radomir Tinkov. All rights reserved.
 *
 * Webfont: Gilroy-Bold by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/bold/
 * Copyright: Copyright &#x00A9; 2016 by Radomir Tinkov. All rights reserved.
 *
 * Webfont: Gilroy-BlackItalic by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/black-italic/
 * Copyright: Copyright &#x00A9; 2015 by Radomir Tinkov. All rights reserved.
 *
 * Webfont: Gilroy-Black by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/black/
 * Copyright: Copyright &#x00A9; 2016 by Radomir Tinkov. All rights reserved.
 *
 *
 * License: https://www.myfonts.com/viewlicense?type=web&buildid=3729617
 * Licensed pageviews: 10,000
 *
 * © 2019 MyFonts Inc
*/

/*
@font-face {
	font-family: 'Gilroy-Thin';
	src: url('../fonts/38E8D1_0_0.eot');
	src: url('../fonts/38E8D1_0_0.eot?#iefix') format('embedded-opentype'),
		url('../fonts/38E8D1_0_0.woff2') format('woff2'),
		url('../fonts/38E8D1_0_0.woff') format('woff'),
		url('../fonts/38E8D1_0_0.ttf') format('truetype');
}

@font-face {
	font-family: 'Gilroy-UltraLightItalic';
	src: url('../fonts/38E8D1_1_0.eot');
	src: url('../fonts/38E8D1_1_0.eot?#iefix') format('embedded-opentype'),
		url('../fonts/38E8D1_1_0.woff2') format('woff2'),
		url('../fonts/38E8D1_1_0.woff') format('woff'),
		url('../fonts/38E8D1_1_0.ttf') format('truetype');
}

@font-face {
	font-family: 'Gilroy-ThinItalic';
	src: url('../fonts/38E8D1_2_0.eot');
	src: url('../fonts/38E8D1_2_0.eot?#iefix') format('embedded-opentype'),
		url('../fonts/38E8D1_2_0.woff2') format('woff2'),
		url('../fonts/38E8D1_2_0.woff') format('woff'),
		url('../fonts/38E8D1_2_0.ttf') format('truetype');
}

@font-face {
	font-family: 'Gilroy-SemiBoldItalic';
	src: url('../fonts/38E8D1_3_0.eot');
	src: url('../fonts/38E8D1_3_0.eot?#iefix') format('embedded-opentype'),
		url('../fonts/38E8D1_3_0.woff2') format('woff2'),
		url('../fonts/38E8D1_3_0.woff') format('woff'),
		url('../fonts/38E8D1_3_0.ttf') format('truetype');
}

@font-face {
	font-family: 'Gilroy-SemiBold';
	src: url('../fonts/38E8D1_4_0.eot');
	src: url('../fonts/38E8D1_4_0.eot?#iefix') format('embedded-opentype'),
		url('../fonts/38E8D1_4_0.woff2') format('woff2'),
		url('../fonts/38E8D1_4_0.woff') format('woff'),
		url('../fonts/38E8D1_4_0.ttf') format('truetype');
}

@font-face {
	font-family: 'Gilroy-UltraLight';
	src: url('../fonts/38E8D1_5_0.eot');
	src: url('../fonts/38E8D1_5_0.eot?#iefix') format('embedded-opentype'),
		url('../fonts/38E8D1_5_0.woff2') format('woff2'),
		url('../fonts/38E8D1_5_0.woff') format('woff'),
		url('../fonts/38E8D1_5_0.ttf') format('truetype');
}

@font-face {
	font-family: 'Gilroy-Regular';
	src: url('../fonts/38E8D1_6_0.eot');
	src: url('../fonts/38E8D1_6_0.eot?#iefix') format('embedded-opentype'),
		url('../fonts/38E8D1_6_0.woff2') format('woff2'),
		url('../fonts/38E8D1_6_0.woff') format('woff'),
		url('../fonts/38E8D1_6_0.ttf') format('truetype');
}

@font-face {
	font-family: 'Gilroy-RegularItalic';
	src: url('../fonts/38E8D1_7_0.eot');
	src: url('../fonts/38E8D1_7_0.eot?#iefix') format('embedded-opentype'),
		url('../fonts/38E8D1_7_0.woff2') format('woff2'),
		url('../fonts/38E8D1_7_0.woff') format('woff'),
		url('../fonts/38E8D1_7_0.ttf') format('truetype');
}

@font-face {
	font-family: 'Gilroy-MediumItalic';
	src: url('../fonts/38E8D1_8_0.eot');
	src: url('../fonts/38E8D1_8_0.eot?#iefix') format('embedded-opentype'),
		url('../fonts/38E8D1_8_0.woff2') format('woff2'),
		url('../fonts/38E8D1_8_0.woff') format('woff'),
		url('../fonts/38E8D1_8_0.ttf') format('truetype');
}

@font-face {
	font-family: 'Gilroy-Medium';
	src: url('../fonts/38E8D1_9_0.eot');
	src: url('../fonts/38E8D1_9_0.eot?#iefix') format('embedded-opentype'),
		url('../fonts/38E8D1_9_0.woff2') format('woff2'),
		url('../fonts/38E8D1_9_0.woff') format('woff'),
		url('../fonts/38E8D1_9_0.ttf') format('truetype');
}

@font-face {
	font-family: 'Gilroy-LightItalic';
	src: url('../fonts/38E8D1_A_0.eot');
	src: url('../fonts/38E8D1_A_0.eot?#iefix') format('embedded-opentype'),
		url('../fonts/38E8D1_A_0.woff2') format('woff2'),
		url('../fonts/38E8D1_A_0.woff') format('woff'),
		url('../fonts/38E8D1_A_0.ttf') format('truetype');
}

@font-face {
	font-family: 'Gilroy-Light';
	src: url('../fonts/38E8D1_B_0.eot');
	src: url('../fonts/38E8D1_B_0.eot?#iefix') format('embedded-opentype'),
		url('../fonts/38E8D1_B_0.woff2') format('woff2'),
		url('../fonts/38E8D1_B_0.woff') format('woff'),
		url('../fonts/38E8D1_B_0.ttf') format('truetype');
}

@font-face {
	font-family: 'Gilroy-HeavyItalic';
	src: url('../fonts/38E8D1_C_0.eot');
	src: url('../fonts/38E8D1_C_0.eot?#iefix') format('embedded-opentype'),
		url('../fonts/38E8D1_C_0.woff2') format('woff2'),
		url('../fonts/38E8D1_C_0.woff') format('woff'),
		url('../fonts/38E8D1_C_0.ttf') format('truetype');
}

@font-face {
	font-family: 'Gilroy-Heavy';
	src: url('../fonts/38E8D1_D_0.eot');
	src: url('../fonts/38E8D1_D_0.eot?#iefix') format('embedded-opentype'),
		url('../fonts/38E8D1_D_0.woff2') format('woff2'),
		url('../fonts/38E8D1_D_0.woff') format('woff'),
		url('../fonts/38E8D1_D_0.ttf') format('truetype');
}

@font-face {
	font-family: 'Gilroy-ExtraBoldItalic';
	src: url('../fonts/38E8D1_E_0.eot');
	src: url('../fonts/38E8D1_E_0.eot?#iefix') format('embedded-opentype'),
		url('../fonts/38E8D1_E_0.woff2') format('woff2'),
		url('../fonts/38E8D1_E_0.woff') format('woff'),
		url('../fonts/38E8D1_E_0.ttf') format('truetype');
}

@font-face {
	font-family: 'Gilroy-ExtraBold';
	src: url('../fonts/38E8D1_F_0.eot');
	src: url('../fonts/38E8D1_F_0.eot?#iefix') format('embedded-opentype'),
		url('../fonts/38E8D1_F_0.woff2') format('woff2'),
		url('../fonts/38E8D1_F_0.woff') format('woff'),
		url('../fonts/38E8D1_F_0.ttf') format('truetype');
}

@font-face {
	font-family: 'Gilroy-BoldItalic';
	src: url('../fonts/38E8D1_10_0.eot');
	src: url('../fonts/38E8D1_10_0.eot?#iefix') format('embedded-opentype'),
		url('../fonts/38E8D1_10_0.woff2') format('woff2'),
		url('../fonts/38E8D1_10_0.woff') format('woff'),
		url('../fonts/38E8D1_10_0.ttf') format('truetype');
}

@font-face {
	font-family: 'Gilroy-Bold';
	src: url('../fonts/38E8D1_11_0.eot');
	src: url('../fonts/38E8D1_11_0.eot?#iefix') format('embedded-opentype'),
		url('../fonts/38E8D1_11_0.woff2') format('woff2'),
		url('../fonts/38E8D1_11_0.woff') format('woff'),
		url('../fonts/38E8D1_11_0.ttf') format('truetype');
}

@font-face {
	font-family: 'Gilroy-BlackItalic';
	src: url('../fonts/38E8D1_12_0.eot');
	src: url('../fonts/38E8D1_12_0.eot?#iefix') format('embedded-opentype'),
		url('../fonts/38E8D1_12_0.woff2') format('woff2'),
		url('../fonts/38E8D1_12_0.woff') format('woff'),
		url('../fonts/38E8D1_12_0.ttf') format('truetype');
}

@font-face {
	font-family: 'Gilroy-Black';
	src: url('../fonts/38E8D1_13_0.eot');
	src: url('../fonts/38E8D1_13_0.eot?#iefix') format('embedded-opentype'),
		url('../fonts/38E8D1_13_0.woff2') format('woff2'),
		url('../fonts/38E8D1_13_0.woff') format('woff'),
		url('../fonts/38E8D1_13_0.ttf') format('truetype');
}

*/

@font-face {
  font-family: 'Gilroy-Regular';
  src: url('../fonts/38E8D1_6_0.eot');
  src: url('../fonts/38E8D1_6_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/38E8D1_6_0.woff2') format('woff2'), url('../fonts/38E8D1_6_0.woff') format('woff'),
    url('../fonts/38E8D1_6_0.ttf') format('truetype');
}

@font-face {
  font-family: 'Gilroy-Bold';
  src: url('../fonts/38E8D1_11_0.eot');
  src: url('../fonts/38E8D1_11_0.eot?#iefix') format('embedded-opentype'),
    url('../fonts/38E8D1_11_0.woff2') format('woff2'),
    url('../fonts/38E8D1_11_0.woff') format('woff'),
    url('../fonts/38E8D1_11_0.ttf') format('truetype');
}
/* Used for tooltip 'i' */
@import url('https://fonts.googleapis.com/css2?family=Lobster+Two:ital,wght@1,700&display=swap');
